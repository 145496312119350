<template>
  <div>
    <div v-if="entityDataFetching" class="current-item-wrapper">
      <div class="container blank text-center">
        <AppLoader />
      </div>
    </div>
    <div v-else-if="!entityDataFetching && !item" class="current-item-wrapper">
      <div class="container">
        <MsgEntityNotFound name="Art Space" />
      </div>
    </div>

    <template v-else-if="item">
      <div class="current-item-wrapper">
        <EntityPageHeader :image="mediaUrl" image-class="square" :title="item.title" :title-after="destinationTitle" />
        <EntityPageTabs :data="tabs" @active-tab="activeTab" />

        <div class="container">
          <div v-if="isComponent === 'data-item-info'" class="data-details__actions d-flex justify-content-center mt-5">
            <TicketRequestAction
              v-if="!museumRole"
              :event="item"
              object="exhibition"
              select-from-exhibitions
              :show="showTourBookingForm"
              tour
              @close="handleCloseTourBookingForm"
            />
            <TicketRequestAction
              v-if="accessTypeChecker.accessByTickets"
              :event="item"
              object="exhibition"
              select-from-exhibitions
            />
            <UseCultivistCardAction
              v-else-if="accessTypeChecker.accessByCard"
              :event="item"
              :is-mobile="isMobileScreen"
              :show-access-instructions="areAccessInstructionsPresent"
              @show-access-instructions="openAccessInfoModal"
              @toggle="showUseCultCardModal = $event"
            />
            <FreeEntryFlag v-else-if="accessTypeChecker.accessFree" />
            <FindOnMapAction @click="showFindOnMap = true" />
            <AddToFavoriteAction :event="item" :is-favorite="isFavoriteState" @click="handleFavoriteAction" />
            <AddToGuideAction :event="item" />
            <AddReviewAction :itemType="$route.name" @click="setDestination(item.destination.title)" />
          </div>
          <DataItemInfo
            v-if="isComponent === 'data-item-info'"
            :item="item"
            @show-access-instructions="openAccessInfoModal"
          />
          <ArtSpaceExhibitions v-else-if="isComponent === 'art-space-exhibitions'" :exhibitions="artSpaceExhibitions" />
        </div>
      </div>

      <EntityDetailsMap
        v-if="isComponent !== 'art-space-exhibitions'"
        :event="item"
        @click-map-area="showFindOnMap = true"
      />
      <AppRelatedSection v-if="relatedRecords.length" :items="relatedRecords" :related-to-title="item.title" />
    </template>

    <AccessInfoModal
      v-if="areAccessInstructionsPresent"
      v-model="isAccessInfoModal"
      :items="item.accessInstructions"
      @close="closeAccessInfoModal"
    />

    <FindOnMap
      v-if="showFindOnMap"
      :item="item"
      :latitude="entityLatitude"
      :longitude="entityLongitude"
      :zoom="13"
      @close="showFindOnMap = false"
      @show-access-instructions="openAccessInfoModal"
    />

    <Transition name="slide-up">
      <AppConfirmationModal
        v-if="showConfirmCancelTourBookingModal"
        :show="showConfirmCancelTourBookingModal"
        header="Cancel the booking"
        @close="closeConfirmCancelTourBookingModal"
      />
    </Transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ImageHandler from '@/helpers/ImageHandler';
import { checkAccessType } from '@/helpers/accessType';
import { ERR_DEFAULT_MSG } from '@/helpers/errorHandler';
import { prepareVariablesForSingleEntityQuery, redirectToSingleEntityRoute } from '@/helpers/graphqlHelper';

import artSpaceQuery from '@/graphql/artSpace/ArtSpace.single.query.gql';
import addToFavoritesMutation from '@/graphql/me/favorite/AddToUserFavorites.mutation.gql';
import deleteFromUserFavoritesMutation from '@/graphql/me/favorite/DeleteFromUserFavorites.mutation.gql';
import cancelTourBookingMutation from '@/graphql/booking/CancelTourBooking.mutation.gql';

import AddToFavoriteAction from '@/components/partials/actions/AddToFavoriteAction';
import DataItemInfo from '@/components/partials/DataItemInfo';
import EntityPageHeader from '@/components/EntityPageHeader.vue';
import MsgEntityNotFound from '@/components/MsgEntityNotFound.vue';
import EntityDetailsMap from '@/components/EntityDetailsMap.vue';
import AppConfirmationModal from '@/components/modals/AppConfirmationModal.vue';

export default {
  name: 'ArtSpaceSinglePage',
  components: {
    AppConfirmationModal,
    EntityDetailsMap,
    EntityPageHeader,
    MsgEntityNotFound,
    AddToFavoriteAction,
    DataItemInfo,
    EntityPageTabs: () => import('@/components/partials/EntityPageTabs'),
    FreeEntryFlag: () => import('@/components/partials/actions/FreeEntryFlag'),
    UseCultivistCardAction: () => import('@/components/partials/actions/UseCultivistCardAction'),
    TicketRequestAction: () => import('@/components/partials/actions/TicketRequestAction'),
    FindOnMapAction: () => import('@/components/partials/actions/FindOnMapAction'),
    AddToGuideAction: () => import('@/components/partials/actions/AddToGuideAction'),
    AddReviewAction: () => import('@/components/partials/actions/AddReviewAction'),
    ArtSpaceExhibitions: () => import('@/components/partials/ArtSpaceExhibitions'),
    AppRelatedSection: () => import('@/components/partials/AppRelatedSection'),
    FindOnMap: () => import('@/components/partials/FindOnMap'),
    AccessInfoModal: () => import('@/components/partials/AccessInfoModal'),
  },
  metaInfo() {
    return {
      title: this.item?.title || 'Museum & Gallery',
    };
  },
  data() {
    return {
      item: null,
      entityDataFetching: true,
      isAccessInfoModal: false,
      isFavoriteState: false,
      relatedRecords: [],
      artSpaceExhibitions: [],
      tabs: ['details'],
      isComponent: 'data-item-info',
      showUseCultCardModal: false,
      showFindOnMap: false,
      isCalledCancelTourBookingAction: false,
      isCalledUpdateTourBookingAction: false,
      showConfirmCancelTourBookingModal: false,
      showTourBookingForm: false,
    };
  },
  computed: {
    ...mapState(['museumRole', 'isMobileScreen']),
    accessTypeChecker() {
      return checkAccessType(this.item.accessType?.id);
    },
    areAccessInstructionsPresent() {
      return !!this.item?.accessInstructions?.length;
    },
    destinationTitle() {
      if (!this.item.destination || !this.item.destination.title) {
        return '';
      }

      let title = this.item.destination.title;
      if (this.item.destination.parent) {
        title += `, ${this.item.destination.parent.title}`;
      }

      return title;
    },
    entityLatitude() {
      return parseFloat(this.item.artSpace ? this.item.artSpace.latitude : this.item.latitude);
    },
    entityLongitude() {
      return parseFloat(this.item.artSpace ? this.item.artSpace.longitude : this.item.longitude);
    },
    mediaUrl() {
      return new ImageHandler().getFirstImgFromMedia(this.item);
    },
  },
  watch: {
    $route(newRoute, oldRoute) {
      if (
        newRoute.path === oldRoute.path &&
        ((oldRoute.query.hasOwnProperty('update-tour-booking') &&
          !newRoute.query.hasOwnProperty('update-tour-booking')) ||
          (oldRoute.query.hasOwnProperty('cancel-tour-booking') &&
            !newRoute.query.hasOwnProperty('cancel-tour-booking')))
      ) {
        return;
      }

      this.tabs = ['details'];
      this.fetchEntityData();
      this.isComponent = 'data-item-info';
    },
    showFindOnMap(val) {
      if (val) {
        this.$store.dispatch('disableScroll');
      } else {
        this.$store.dispatch('enableScroll');
      }
    },
  },

  created() {
    this.isCalledCancelTourBookingAction = this.$route.query.hasOwnProperty('cancel-tour-booking');
    this.isCalledUpdateTourBookingAction = this.$route.query.hasOwnProperty('update-tour-booking');
    this.fetchEntityData();
  },

  methods: {
    resetTourBookingActions() {
      if (this.isCalledCancelTourBookingAction || this.isCalledUpdateTourBookingAction) {
        this.showConfirmCancelTourBookingModal = false;
        this.showTourBookingForm = false;
        this.isCalledCancelTourBookingAction = false;
        this.isCalledUpdateTourBookingAction = false;
        this.$router.replace({ ...this.$route, query: {} });
      }
    },
    handleCloseTourBookingForm() {
      this.resetTourBookingActions();
    },
    closeConfirmCancelTourBookingModal(answer) {
      if (!answer) {
        this.resetTourBookingActions();
        return;
      }

      this.$store.dispatch('showLoaderScreen', true);

      this.$apollo
        .mutate({
          mutation: cancelTourBookingMutation,
          variables: { id: this.item.activeTourBooking.id },
        })
        .then(() => {
          this.$store.dispatch('showLoaderScreen', false);
          this.item.activeTourBooking = null;
          this.$toast.success('Thank you, your booking has now been cancelled.');
          this.resetTourBookingActions();
        })
        .catch((e) => {
          this.$store.dispatch('showLoaderScreen', false);
          const error = e?.graphQLErrors?.[0]?.message || ERR_DEFAULT_MSG;
          this.$toast.error(error);
        });
    },
    openAccessInfoModal() {
      if (this.areAccessInstructionsPresent) {
        this.isAccessInfoModal = true;
        this.$store.dispatch('disableScroll');
      }
    },
    closeAccessInfoModal() {
      const leaveScrollDisabled = this.showFindOnMap || (this.isMobileScreen && this.showUseCultCardModal);
      if (!leaveScrollDisabled) {
        this.$store.dispatch('enableScroll');
      }
    },
    activeTab(item) {
      switch (item) {
        case 'details':
          this.isComponent = 'data-item-info';
          break;
        case 'exhibitions':
          this.isComponent = 'art-space-exhibitions';
          break;
        case 'access info':
          this.openAccessInfoModal();
          break;
      }
    },
    async fetchEntityData() {
      const variables = prepareVariablesForSingleEntityQuery(this.$route.params);

      this.entityDataFetching = true;

      try {
        const { data } = await this.$apollo.query({ query: artSpaceQuery, variables });
        const item = data?.entity || null;

        if (variables.onlyId && item?.id) {
          await this.$router.replace(
            redirectToSingleEntityRoute(this.$route.name, item.id, variables.slug, { query: this.$route.query })
          );
          return;
        }

        this.entityDataFetching = false;
        this.item = item;

        if (!this.item) {
          return;
        }

        this.isFavoriteState = this.item.is_favorite_by_current_user;
        this.relatedRecords = this.item.relatedRecords || [];

        if (this.item.exhibitions_for_display.length) {
          this.artSpaceExhibitions = this.item.exhibitions_for_display;
          if (this.artSpaceExhibitions.length && !this.tabs.includes('exhibitions')) {
            this.tabs.push('exhibitions');
          }
        }

        if (this.item?.accessInstructions.length) {
          this.tabs.push('access info');
        }

        if (this.isCalledCancelTourBookingAction || this.isCalledUpdateTourBookingAction) {
          if (!this.item?.activeTourBooking) {
            this.resetTourBookingActions();
          } else if (this.isCalledCancelTourBookingAction) {
            this.showConfirmCancelTourBookingModal = true;
          } else if (this.isCalledUpdateTourBookingAction) {
            this.showTourBookingForm = true;
          }
        }
      } catch {
        this.entityDataFetching = false;
      }
    },
    setDestination(destination) {
      this.$store.dispatch('destinationReview', destination);
    },
    handleFavoriteAction() {
      if (!this.isFavoriteState) {
        this.$apollo
          .mutate({
            mutation: addToFavoritesMutation,
            variables: {
              entities: [{ id: this.item.id, type: this.item.__typename }],
            },
          })
          .then(({ data }) => {
            this.isFavoriteState = data.addToUserFavorites;
          });
        return;
      }

      this.$apollo
        .mutate({
          mutation: deleteFromUserFavoritesMutation,
          variables: {
            entities: [{ id: this.item.id, type: this.item.__typename }],
          },
        })
        .then(({ data }) => {
          this.isFavoriteState = !data.deleteFromUserFavorites;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.current-item-wrapper {
  margin-top: 100px;
}
.blank {
  padding-top: 50px;
}
</style>
